import AccountSettings from '@/bundles/Account/models/AccountSettings';
import { getDefaultPermissions } from '@/bundles/App/helpers';
import { OccupierRentType } from '@/bundles/Occupier/types';

export default class AccountSettingsToMapper {
  mapToModel (data: any): AccountSettings {
    const settings = new AccountSettings();
    settings._id = data._id;
    settings._key = data._key;
    settings.email_sender = data.email_sender;
    settings.email_footer = data.email_footer;
    settings.pressReleaseDefaults = data.pressReleaseDefaults || {};
    settings.emailBlastDefaults = data.emailBlastDefaults || {};
    settings.accounting_method = data.accounting_method;
    settings.press_release_sets = data.press_release_sets;
    settings.syndication_set = data.syndication_set;
    settings.installer_set = data.installer_set;
    settings.accounting = data.accounting || {
      accounting_method: 'cash',
      fiscalYearEnd: {
        day: null,
        month: null,
      },
      wires: {
        account_number: null,
        bank_name: null,
        routing_number: null,
        custom_content: null
      },
      show_banking_instructions: false
    };
    settings.accounting_fiscal_year_ending = data.accounting_fiscal_year_ending;
    settings.created = data.created;
    settings.updated = data.updated;
    settings.verification_send_message = data.verification_send_message;
    settings.verification_response_message = data.verification_response_message;
    settings.properties = data.properties;
    settings.map = data.map;
    settings.customNavigationLinks = data.customNavigationLinks;
    settings.report_settings = data.report_settings;
    settings.verification_listing_due = data.verification_listing_due;
    settings.occupier_default_rent_type = data.occupier_default_rent_type || OccupierRentType.per_year;
    settings.permissions = data.permissions || getDefaultPermissions();
    settings.invoice_billing = data.invoice_billing || { key: null, name: null, email: null };
    settings.invoice_billing_custom = data.invoice_billing_custom || { name: null, email: null };
    settings.report_ranges = data.report_ranges || [];
    settings.default_invoice_message = data.default_invoice_message || '';
    settings.company_website = data.company_website || null;
    settings.max_export_records = data.max_export_records || 250;
    settings.limit_exports = data.limit_exports || false;
    settings.allow_procurement = data.allow_procurement || false;
    settings.allow_edit_requests = data.allow_edit_requests || false;
    settings.date_display_format = data.date_display_format || 'M/D/YYYY';
    settings.date_picker_format = data.date_picker_format || 'M/D/YYYY';
    settings.use_types_by_property_type = data.use_types_by_property_type || false;
    return settings;
  }
}
